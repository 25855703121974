<template>
    <div>
      <div class="flex md12">




        <va-modal
          v-model="CurrentTicketPopup"
          hideDefaultActions
          class="flex xs12"
          title="Closed Captioning Data"
          size="large"
          ok-text="Ok">
        <section>  
          <!-- ID's Area -->
          <p>
            <b>Name: </b> 
             {{ this.currentData.patientName }}
          </p>
          <p>
            <b>Question: </b> 
            {{  this.currentData.questionName }}
          </p>

          <!-- Patient Area -->
          <hr/>
          <p>
            <b>Patient - Model ID: </b> 
             {{ this.currentData.patientModelID }}
          </p>
          <p>
            <b>Patient - Model Name: </b> 
            {{ this.currentData.patientModelName }}
          </p>
          <!-- File Area -->
          <hr/>
          <p>
            <b>File - File Dir: </b> 
            {{ this.currentData.fileDir }}
          </p>
          <p>
            <b>File - Filename: </b> 
            {{ this.currentData.filename }}
          </p>
          <p>
            <b>File - FileURL: </b> 
            <a :href="this.currentData.fileURL" target="_blank">{{ this.currentData.fileURL }}</a>
          </p>
          <hr/>
          <!-- Data's Area -->
          <p>
            <b>Created At: </b> 
            {{ this.currentData.createdAt }}
          </p>
          <p>
            <b>Updated At: </b> 
            {{ this.currentData.updatedAt }}
          </p>
        </section>
         <!-- create 5 button with different actions -->
         <div class="pt-2">
            <va-button
                slot="actions"
                @click="CurrentTicketPopup = false"
                color="gray"
                class="mr-2"
            > Close </va-button>
          </div>
        </va-modal>


        <va-modal
          v-model="CurrentEditingTable"
          ref="modal"
          hideDefaultActions
          class="flex xs12"
          title="Closed Captioning Table"
          @cancel="onCancel"
          size="large"
          ok-text="Ok">
        <section>  
          <!-- ID's Area -->
          <va-data-table
            :fields="EditFields"
            :data="filteredEditData"
            :per-page="parseInt(perPage)"
            :loading="loading"
            clickable
            hoverable
          >
          <template slot="actions" slot-scope="props" >
            <div class="flex row">
                <va-button @click="editColumn(props.rowData)" title="Edit Column"   icon="fa fa-edit"/>
                <va-button @click="deleteColumn(props.rowData)" title="Delete Column"  icon="fa fa-trash"/>
            </div>
          </template>
         </va-data-table>
        </section>  
         <!-- create 5 button with different actions -->
         <div class="pt-2">
             <va-button
                slot="actions"
                @click="SaveChangesInCC()"
                color="success"
                class="mr-2"
            > Save Changes </va-button>
            <va-button
                slot="actions"
                @click="AddNewCC()"
                color="info"
                class="mr-2"
            > Add New CC </va-button>
            <va-button
                slot="actions"
                @click="closeEditingModal()"
                color="gray"
                class="mr-2"
            > Close </va-button>
          </div>
        </va-modal>


        <va-card title="Your Closed Captions">
          <div class="row align--center">
            <div class="flex xs12 md6">
              <va-input
                :value="term"
                :placeholder="'Search'"
                @input="search"
                removable
              >
                <va-icon
                  name="fa fa-search"
                  slot="prepend"
                />
              </va-input>
            </div>
            <div class="flex xs12 md3 offset--md3">
              <va-select
                v-model="perPage"
                :label="$t('tables.perPage')"
                :options="perPageOptions"
                no-clear
              />
            </div>
          </div>
          <va-data-table
            :fields="fields"
            :data="filteredData"
            :per-page="parseInt(perPage)"
            :loading="loading"
            clickable
            hoverable
          >
          <template slot="actions" slot-scope="props" >
            <div class="flex row">
                <va-button @click="viewMoreData(props.rowData)" title="View More"   icon="fa fa-info"/>
                <va-button @click="editClosedCaption(props.rowData)" title="Edit Closed Caption"   icon="fa fa-edit"/>
                <va-button @click="deleteClosedCaption(props.rowData.id, props.rowData.fileDir)" title="Delete Closed Caption"  icon="fa fa-trash"/>
            </div>
          </template>
         </va-data-table>
        </va-card>
      </div>
    </div>
  </template>
  <script>
  import {debounce} from 'lodash';
  import moment from 'moment';
  import firebase from 'firebase';
  import LogCreator from "../../class/LogCreator"
  export default {
    data() {
      return {
        perPageOptions: ['10', '50', '100', '250'],
        perPage: '50',
        closedCaption: [],
        currentEditingCC: [],
        currentChangingCC: [],
        hasChanges: false,
        currentData: {},
        CurrentEditingTable: false,
        CurrentTicketPopup: false,
        term: '',
        loading: false,
      };
    },
    async created() {
      await this.updateTable()
      this.loading = false;
    },
    computed: {
      EditFields(){
        return [{
          name: 'startTime',
          title: 'From',
          sortField: 'startTime',
          width: '13%',
        },{
          name: 'endTime',
          title: 'Until',
          sortField: 'endTime',
          width: '13%',
        },
        {
          name: 'ref',
          title: 'Text',
          sortField: 'ref',
          width: '16%',
        },
        {
          name: '__slot:actions',
          title: 'Actions',
          width: '20%',
        },
        ];
      },
      fields() {
        return [{
          name: 'patientName',
          title: 'Patient Name',
          sortField: 'patientName',
          width: '13%',
        },{
          name: 'questionName',
          title: 'Question Name',
          sortField: 'questionName',
          width: '13%',
        },
        {
          name: 'fileDir',
          title: 'File Directory',
          sortField: 'fileDir',
          width: '16%',
        },
        {
          name: 'createdAt',
          title: 'Created At',
          sortField: 'createdAt',
          width: '16%',
        },
        {
          name: 'updatedAt',
          title: 'Updated At',
          sortField: 'updatedAt',
          width: '16%',
        },
        {
          name: '__slot:actions',
          title: 'Actions',
          width: '20%',
        },
        ];
      },
      filteredEditData(){
        //filter by who has startTime first and endTime last
        return this.currentEditingCC.sort((a, b) => {
            return new Date(a.startTime) - new Date(b.startTime);
        });
      },
      filteredData() {
        //order by createdAt
        this.closedCaption.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
        });
        if (!this.term || this.term.length < 1) {
          return this.closedCaption;
        }
        return this.closedCaption.filter((item) => {
          let returnData = false;
          if (item.patientName) {
            if (item.patientName.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
              returnData = true;
            }
          }
          if (item.questionName) {
            if (item.questionName.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
              returnData = true;
            }
          }
          if (item.fileDir) {
            if (item.fileDir.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
              returnData = true;
            }
          }
          if (item.createdAt) {
            if (item.createdAt.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
              returnData = true;
            }
          }
          if (item.updatedAt) {
            if (item.updatedAt.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
              returnData = true;
            }
          }
          return returnData;
        });
      },
    },
   
    methods: {
      showConfirmPopup(alreadyClosed = false){
        //check if there are changes
        if(this.hasChanges){
          if(alreadyClosed){
            //warn the user that he closes the modal without saving, dont use are you sure, use oops
            this.$swal.fire({
              title: 'Oops!',
              text: "You have unsaved changes. Please do not close the modal without saving.",
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok'
            })
            this.hasChanges = false;
          }else{  
            this.$swal.fire({
              title: 'Are you sure?',
              text: "You have unsaved changes. Are you sure you want to close this modal?",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, close it!'
            }).then((result) => {
              if (result.value) {
                this.CurrentEditingTable = false;
                this.hasChanges = false;
              }
            })
          }
        }
      },
      closeEditingModal(){
        this.showConfirmPopup()
      },
      onCancel(){
        this.showConfirmPopup(true)
      },
      async updateTable(){
        this.closedCaption = []
      await firebase.firestore().collection('closedCaptions').get().then((snapshot) => {
          const closedCaptions = snapshot.docs.map(doc => {
              let data = doc.data();
              //foreach item in data.file.fileJson append a random index key
              data.file.fileJson.forEach((item, index) => {
                item.index = index
              })
      
              this.closedCaption.push({
                  id: doc.id,
                  modelID: data.patient ? data.patient.modelID : "N/A",
                  name: data.name,
                  patientName: data.patientName ? data.patientName : "Demonstration",
                  questionName: data.question ? data.question.value : data.file.fileName.replace('.txt', ''),

                  patientModelID: data.patient ? data.patient.modelID : "N/A",
                  patientModelName: data.patient ? data.patient.modelName : "N/A",

                  fileDir: data.file.fileDir,
                  fileJson: data.file.fileJson,
                  filename: data.file.fileName,
                  fileURL: data.file.fileURL,

                  createdAt: moment(new Date(data.createdAt.seconds*1000)).format('MM/D/YYYY, h:mm:ss a'),
                  updatedAt: moment(new Date(data.updatedAt.seconds*1000)).format('MM/D/YYYY, h:mm:ss a'),
              })
          })
        })
      },
      async viewMoreData(data){
        this.CurrentTicketPopup = true;
        this.currentData = data;
      },
      async editClosedCaption(data){
        this.CurrentEditingTable  = true;
        this.currentEditingCC = data.fileJson
        this.currentChangingCC = data
      },
      async deleteClosedCaption(id, fileDir){
          this.$swal.fire({
              title: 'Are you sure?',
              html: "You won't be able to revert this! This will completely remove CC with Id: <b>" + id + "</b> from the firestore!",
              icon: 'warning',
              showDenyButton: false,
              showCancelButton: true,
              confirmButtonText: 'Yes',
          }).then(async (result) => {
            if (result.value) {
                this.$swal.fire({
                    title: 'Deleting..',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    onOpen: async () => {
                        this.$swal.showLoading();
                        try{
                          //delete from storage
                          await firebase.storage().ref(fileDir).delete()
                        }catch{
                          //continue
                        }
                        await firebase.firestore().collection('closedCaptions').doc(id).delete()
                        await this.updateTable();
                        let admin = await firebase.auth().currentUser;
                        //Clear old possible logs
                        LogCreator.clearLog();
                        //setup admin data
                        LogCreator.setAdmin(admin)
                        //setup event data
                        LogCreator.setEvent('delete', id)

                        LogCreator.setChanges([], [])
                        //create log and assign Area
                        const LogObject = LogCreator.create('Closed Caption', firebase.firestore.Timestamp.now())
                        let notificationObject = LogCreator.createNotification('System')
                        //save into firestore
                        await firebase.firestore().collection('HistoryLogs').add(LogObject)
                        await firebase.firestore().collection('Notifications').add(notificationObject)
                        this.$swal.fire({
                          title: 'Deleted!',
                          html: "The closed caption has been deleted!",
                          icon: 'success',
                          showDenyButton: false,
                        })
                    },  
                  })
                }
              })
      },
      editColumn(data, index){
        //show sweetalert with inputs to change current data
        this.$swal.fire({
          title: 'Edit Column',
          html: '<label>Start Time</label> <br/><input type="number" id="swal-input1" class="swal2-input" value="'+data.startTime+'">   <br/><label>End Time</label> <br/><input type="number" id="swal-input2" class="swal2-input" value="'+data.endTime+'"> <br/><label>Text</label> <br/><input id="swal-input3" class="swal2-input" value="'+data.ref+'">',
          focusConfirm: false,
          confirmButtonText: 'Save Locally',
          showCancelButton: true,
          preConfirm: () => {
            return [
              document.getElementById('swal-input1').value,
              document.getElementById('swal-input2').value,
              document.getElementById('swal-input3').value,
            ]
          }
        }).then((result) => {
          if (result.value) {
            //update current column
            this.hasChanges = true;
            this.currentEditingCC[data.index].startTime = result.value[0];
            this.currentEditingCC[data.index].endTime = result.value[1];
            this.currentEditingCC[data.index].ref = result.value[2];
          }
        })
      },
      deleteColumn(data){
        let keyToDelete = data.index
        this.currentEditingCC = this.currentEditingCC.filter((item, index) => {
          return index !== keyToDelete
        })
        //percorre o array this.currentEditingCC e atualiza o index de cada item
        this.currentEditingCC.forEach((item, index) => {
          item.index = index
        })
        this.hasChanges = true;
      },
      convertToSRT(json){
            //convert json to srt
            let srt = "";
            let counter = 1;
            for (let item of json){
              srt += counter + "\n";
              srt += item.startTime + " --> " + item.endTime + "\n";
              srt += item.ref + "\n\n";
              counter++;
            }
            return srt;
      },
      AddNewCC(){
        //abre um swal igual o da função editColumn, porém para adicionar um novo item no array this.currentEditingCC
        this.$swal.fire({
          title: 'Add New Column',
          html: '<label>Start Time</label> <br/><input type="number" id="swal-input1" class="swal2-input" value="0">   <br/><label>End Time</label> <br/><input type="number" id="swal-input2" class="swal2-input" value="0"> <br/><label>Text</label> <br/><input id="swal-input3" class="swal2-input" value="">',
          focusConfirm: false,
          showCancelButton: true,
          preConfirm: () => {
            return [
              document.getElementById('swal-input1').value,
              document.getElementById('swal-input2').value,
              document.getElementById('swal-input3').value,
            ]
          }
        }).then((result) => {
          if (result.value) {
            //update current column
            this.currentEditingCC.push({
              index: this.currentEditingCC.length,
              startTime: result.value[0],
              endTime: result.value[1],
              ref: result.value[2],
            })
            //run through the array and update the index of each item
            this.currentEditingCC.forEach((item, index) => {
              item.index = index
            })
            this.hasChanges = true;
          }
        })

      },  
      SaveChangesInCC(){
        //get this.currentEditingCC and update it in firestore
        this.$swal.fire({
          title: 'Are you sure?',
          html: "You won't be able to revert this! This will completely update CC with Id: <b>" + this.currentChangingCC.id + "</b> in the firestore!",
          icon: 'warning',
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: 'Yes',
      }).then(async (result) => {
        if (result.value) {
            this.$swal.fire({
                title: 'Updating..',
                text: 'Please wait while we update this CC',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                onOpen: async () => {
                    this.$swal.showLoading();
                    //change content of this.currentChangingCC.fileDir from storage to content of this.currentEditingCC
                    let directory = firebase.storage().ref().child(this.currentChangingCC.fileDir);
                    let srt = this.convertToSRT(this.currentEditingCC)
                    let textURL = await directory.putString(srt, 'raw', {contentType: 'text/plain'}).then(async snapshot => {
                      return snapshot.ref.getDownloadURL((url) => {
                        return url;
                      })
                    })
                    //to make sure that the data is updated in firestore before the log is created
                    await firebase.firestore().collection('closedCaptions').doc(this.currentChangingCC.id). get().then(async doc => {
                      this.currentChangingCCFirstVersion = doc.data()
                    })
                    let admin = await firebase.auth().currentUser;
                    //Clear old possible logs
                    LogCreator.clearLog();
                    //setup admin data
                    LogCreator.setAdmin(admin)
                    //setup event data
                    LogCreator.setEvent('update', this.currentChangingCC.id)
                    LogCreator.setChanges(this.currentEditingCC, this.currentChangingCCFirstVersion.file.fileJson)
                    //create log and assign Area
                    const LogObject = LogCreator.create('Closed Caption', firebase.firestore.Timestamp.now())
                    let notificationObject = LogCreator.createNotification('System')
                    //save into firestore
                    await firebase.firestore().collection('HistoryLogs').add(LogObject)
                    await firebase.firestore().collection('Notifications').add(notificationObject)
                    await firebase.firestore().collection('closedCaptions').doc(this.currentChangingCC.id).update({
                      file: {
                        fileJson: this.currentEditingCC,
                        fileDir: this.currentChangingCC.fileDir,
                        fileURL: textURL,
                        fileName: this.currentChangingCC.filename ? this.currentChangingCC.filename : "",
                      },
                      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                    })
                    await this.updateTable();
                    this.CurrentEditingTable = false;
                    this.hasChanges = false;
                    this.$swal.fire({
                      title: 'Updated!',
                      html: "The closed caption has been updated!",
                      icon: 'success',
                      showDenyButton: false,
                    })
                },  
              })
            }
      })
      },
      search: debounce(function(term) {
        /* eslint-disable */
        this.term = term;
      }, 100),
    }
  };
  </script>
  